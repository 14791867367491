const menu = document.getElementById("Menu") as HTMLElement;
menu?.addEventListener('click', () => {
    document.getElementById("mobileMenu")?.classList.remove("hidden")
    document.getElementById("navbar")?.classList.remove("fixed");
})
const closeMobileMenu = document.getElementById("closeMobileMenu") as HTMLElement;
closeMobileMenu?.addEventListener('click', () => {
    document.getElementById("mobileMenu")?.classList.add("hidden");
    document.getElementById("navbar")?.classList.add("fixed");
})
const main = document.getElementById("main") as HTMLElement;
main?.addEventListener('click', () => {
    document.getElementById("mobileMenu")?.classList.add("hidden")
})

const webBtn = document.getElementById("webBtn") as HTMLButtonElement;
const UxUiBtn = document.getElementById("UxUiBtn") as HTMLButtonElement;
const seoBtn = document.getElementById("seoBtn") as HTMLButtonElement;
const automationBtn = document.getElementById("automationBtn") as HTMLButtonElement;
const mentorshipBtn = document.getElementById("mentorshipBtn") as HTMLButtonElement;
const tableBtn = document.getElementById("tableBtn") as HTMLButtonElement;
const tableCloseBtn = document.getElementById("tableCloseBtn") as HTMLButtonElement;


const webServicesDiv = document.getElementById("web-services") as HTMLDivElement;
const UxUiDiv = document.getElementById("UxUi") as HTMLDivElement;
const seoDiv = document.getElementById("seo") as HTMLDivElement;
const automationDiv = document.getElementById("automation") as HTMLDivElement;
const mentorshipDiv = document.getElementById("mentorship") as HTMLDivElement;

webBtn?.addEventListener('click', () => {
    webServicesDiv.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' })
})
UxUiBtn?.addEventListener('click', () => {
    UxUiDiv.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' })
})
seoBtn?.addEventListener('click', () => {
    seoDiv.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' })
})
automationBtn?.addEventListener('click', () => {
    automationDiv.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' })
})
mentorshipBtn?.addEventListener('click', () => {
    mentorshipDiv.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' })
})

// Terms of Service JavaScript
const introBtn = document.getElementById("introBtn") as HTMLButtonElement;
const acceptBtn = document.getElementById("acceptBtn") as HTMLButtonElement;
const userBtn = document.getElementById("UserBtn") as HTMLButtonElement;
const serviceBtn = document.getElementById("ServiceBtn") as HTMLButtonElement;
const intellBtn = document.getElementById("IntellBtn") as HTMLButtonElement;
const privacyBtn = document.getElementById("PrivacyBtn") as HTMLButtonElement;
const limitationBtn = document.getElementById("LimitationBtn") as HTMLButtonElement;
const terminationBtn = document.getElementById("TerminationBtn") as HTMLButtonElement;
const govBtn = document.getElementById("GovBtn") as HTMLButtonElement;
const severabilityBtn = document.getElementById("SeverabilityBtn") as HTMLButtonElement;
const entireBtn = document.getElementById("EntireBtn") as HTMLButtonElement;

const introDiv = document.getElementById("Introduction") as HTMLDivElement;
const acceptDiv = document.getElementById("Acceptance-of-Terms") as HTMLDivElement;
const userDiv = document.getElementById("User-Responsibilities") as HTMLDivElement;
const serviceDiv = document.getElementById("Services-Offered") as HTMLDivElement;
const intellDiv = document.getElementById("Intellectual-Property") as HTMLDivElement;
const privacyDiv = document.getElementById("Privacy-Policy") as HTMLDivElement;
const limitationDiv = document.getElementById("Limitation-of-Liability") as HTMLDivElement;
const terminationDiv = document.getElementById("Termination") as HTMLDivElement;
const govDiv = document.getElementById("Governing-Law") as HTMLDivElement;
const severabilityDiv = document.getElementById("Severability") as HTMLDivElement;
const entireDiv = document.getElementById("Entire-Agreement") as HTMLDivElement;

introBtn?.addEventListener('click', () => {
    introDiv.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
});
acceptBtn?.addEventListener('click', () => {
    acceptDiv.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
});
userBtn?.addEventListener('click', () => {
    userDiv.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
});
serviceBtn?.addEventListener('click', () => {
    serviceDiv.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
});
intellBtn?.addEventListener('click', () => {
    intellDiv.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
});
privacyBtn?.addEventListener('click', () => {
    privacyDiv.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
});
limitationBtn?.addEventListener('click', () => {
    limitationDiv.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
});
terminationBtn?.addEventListener('click', () => {
    terminationDiv.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
});
govBtn?.addEventListener('click', () => {
    govDiv.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
});
severabilityBtn?.addEventListener('click', () => {
    severabilityDiv.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
});
entireBtn?.addEventListener('click', () => {
    entireDiv.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
});

//basil
const TreatingBtn = document.getElementById("TreatingBtn") as HTMLButtonElement;
const CollectionBtn = document.getElementById("CollectionBtn") as HTMLButtonElement;
const EmailBtn = document.getElementById("EmailBtn") as HTMLButtonElement;
const ServicesBtn = document.getElementById("ServicesBtn") as HTMLButtonElement;
const WebBtn = document.getElementById("WebBtn") as HTMLButtonElement;
const PrivacyBtn = document.getElementById("PrivacyBtn") as HTMLButtonElement;
const ContactBtn = document.getElementById("ContactBtn") as HTMLButtonElement;


const treatingDiv =document.getElementById("Treating") as HTMLDivElement;
const CollectionDiv =document.getElementById("Collection") as HTMLDivElement;
const EmailDiv =document.getElementById("Email") as HTMLDivElement;
const ServicesDiv =document.getElementById("Services") as HTMLDivElement;
const WebDiv =document.getElementById("Web") as HTMLDivElement;
const PrivacyDiv =document.getElementById("Privacy") as HTMLDivElement;
const ContactDiv =document.getElementById("Contact") as HTMLDivElement;
//basil
TreatingBtn?.addEventListener('click', () => {
    treatingDiv.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' })
})

CollectionBtn?.addEventListener('click', () => {
    CollectionDiv.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' })
})

EmailBtn?.addEventListener('click', () => {
    EmailDiv.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' })
})

ServicesBtn?.addEventListener('click', () => {
    ServicesDiv.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' })
})

WebBtn?.addEventListener('click', () => {
    WebDiv.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' })
})

PrivacyBtn?.addEventListener('click', () => {
    PrivacyDiv.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' })
})

ContactBtn?.addEventListener('click', () => {
    ContactDiv.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' })
})