export const preventLetterInput = (inputId: string): void => {
const inputElement = document?.getElementById(inputId) as HTMLInputElement;

if (inputElement) {
  inputElement?.addEventListener('input', handleInputChange);
} else {
  console.error(`Input element with ID '${inputId}' not found.`);
}
  
function handleInputChange(event: Event): void {
  const target = event.target as HTMLInputElement;
  if (containsLetters(target.value)) {
    showLabel(target.id, 1);
  }else {
    hideLabel(target.id, 1);
  }
    target.value = target.value.replace(/[a-zA-Z]/g, '');
  }
};
  
const containsLetters = (str: string): boolean => {
  return /\p{L}/u.test(str);
};
  
const showLabel = (inputId: string, index: number): void => {
  const labelElements = document.querySelectorAll(`label[for="${inputId}"]`);
  if (labelElements.length > index) {
    (labelElements[index] as HTMLLabelElement).classList.remove('hidden');
  }
};
  
const hideLabel = (inputId: string, index: number): void => {
  const labelElements = document?.querySelectorAll(`label[for="${inputId}"]`);
  if (labelElements.length > index) {
    (labelElements[index] as HTMLLabelElement).classList.add('hidden');
  }
};