let mybutton = document.getElementById("btn-back-to-top");

window.onscroll = function () {
    scrollFunction();
};

function scrollFunction() {
    if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
    ) {
        if (mybutton) {
            mybutton.style.display = "block";
        }
    } else {
        if (mybutton) {
            mybutton.style.display = "none";
        }
    }
}


mybutton?.addEventListener("click", backToTop);

function backToTop() {
    const start = window.scrollY || document.documentElement.scrollTop;
    const duration = 1900;
    const startTime = performance.now();

    function scrollStep(currentTime: any) {
        const timeElapsed = currentTime - startTime;
        const progress = Math.min(timeElapsed / duration, 1);
        const easing = easeInOutExpo(progress);

        window.scrollTo(0, start * (1 - easing));
        if (timeElapsed < duration) {
            requestAnimationFrame(scrollStep);
        } else {
            window.scrollTo(0, 0);
        }
    }

    requestAnimationFrame(scrollStep);
}

function easeInOutExpo(t: any) {
    return t === 0
        ? 0
        : t === 1
        ? 1
        : t < 0.5
        ? Math.pow(2, 10 * (t * 2 - 1)) / 2
        : (2 - Math.pow(2, -10 * (t * 2 - 1))) / 2;
}
